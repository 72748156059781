import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ApiKeyControllerService } from './api/apiKeyController.service';
import { AuthTestControllerService } from './api/authTestController.service';
import { AuthenticationControllerService } from './api/authenticationController.service';
import { CalibrationControllerService } from './api/calibrationController.service';
import { DailyReportGeneratorControllerService } from './api/dailyReportGeneratorController.service';
import { ErrorCodeControllerService } from './api/errorCodeController.service';
import { ExternalSurveyControllerService } from './api/externalSurveyController.service';
import { GatewayControllerService } from './api/gatewayController.service';
import { LiveSurveyControllerService } from './api/liveSurveyController.service';
import { MachineControllerService } from './api/machineController.service';
import { ProductControllerService } from './api/productController.service';
import { ProfileControllerService } from './api/profileController.service';
import { PublicDownloadControllerService } from './api/publicDownloadController.service';
import { PublicPasswordResetControllerService } from './api/publicPasswordResetController.service';
import { PublicRegistrationControllerService } from './api/publicRegistrationController.service';
import { PublicVersionControllerService } from './api/publicVersionController.service';
import { ShiftGeneratorControllerService } from './api/shiftGeneratorController.service';
import { ShiftRuleControllerService } from './api/shiftRuleController.service';
import { ShiftRulesetControllerService } from './api/shiftRulesetController.service';
import { SurveyControllerService } from './api/surveyController.service';
import { SurveyOverviewControllerService } from './api/surveyOverviewController.service';
import { SurveyRunControllerService } from './api/surveyRunController.service';
import { SurveySynchronizeControllerService } from './api/surveySynchronizeController.service';
import { TenantControllerService } from './api/tenantController.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [ApiKeyControllerService, AuthTestControllerService, AuthenticationControllerService, CalibrationControllerService, DailyReportGeneratorControllerService, ErrorCodeControllerService, ExternalSurveyControllerService, GatewayControllerService, LiveSurveyControllerService, MachineControllerService, ProductControllerService, ProfileControllerService, PublicDownloadControllerService, PublicPasswordResetControllerService, PublicRegistrationControllerService, PublicVersionControllerService, ShiftGeneratorControllerService, ShiftRuleControllerService, ShiftRulesetControllerService, SurveyControllerService, SurveyOverviewControllerService, SurveyRunControllerService, SurveySynchronizeControllerService, TenantControllerService]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
