import { AuthenticationTokenDTO } from '../../swagger-generated-client-api';

export enum EScope {
  SUPER_ADMIN = 'runs:view-all',
  SENSORDATA_ACCESS = 'sensordata:access',
  COMPANIES_VIEW = 'companies:view',
  USERS_INVITE = 'users:invite',
  USERS_DEACTIVATE = 'users:deactivate',
  RUNS_VIEW_ALL = 'runs:view-all',
  RUNS_VIEW_COMPANY = 'runs:view-company',
  RUNS_UPLOAD = 'runs:upload',
  SURVEYS_READ = 'surveys:read',
  SURVEY_HISTORY_READ = 'survey-history:read',
  SURVEYS_WRITE = 'surveys:write',
  GATEWAYS_WRITE = 'gateways:write',
  GATEWAYS_READ = 'gateways:read',
  GATEWAYS_READ_ALL = 'gateways:read-all',
  COMPANY_DASHBOARD_READ = 'company-dashboard:read',
  COMPANY_DASHBOARD_WRITE = 'company-dashboard:write',
  API_KEY_WRITE = 'api-key:write',
  API_KEY_READ = 'api-key:read',
  MACHINES_WRITE = 'machines:write',
  MACHINES_READ = 'machines:read',
  ERROR_CODES_WRITE = 'error-codes:write',
  CALIBRATION_ALL = 'calibration:all'
}

export interface AuthenticationTokenLocalStorage {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  expiresIn: number | undefined;
  scopes: EScope[] | undefined;
}

export class AuthenticationToken {
  protected constructor(
    public readonly accessToken: string | undefined,
    public readonly refreshToken: string | undefined,
    public readonly expiresIn: number | undefined,
    public readonly scopes: EScope[] | undefined
  ) {}

  public static fromDTO(dto: AuthenticationTokenDTO): AuthenticationToken {
    return new AuthenticationToken(dto.access_token, dto.refresh_token, dto.expires_in, dto.scope as EScope[]);
  }

  public static deserialize(serialize: AuthenticationTokenLocalStorage): AuthenticationToken {
    return new AuthenticationToken(
      serialize.accessToken,
      serialize.refreshToken,
      serialize.expiresIn,
      serialize.scopes as EScope[]
    );
  }

  public serialize(): AuthenticationTokenLocalStorage {
    return {
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
      expiresIn: this.expiresIn,
      scopes: this.scopes
    };
  }

  public hasScope(neededScopes: EScope[]): boolean {
    return !!(this.scopes && AuthenticationToken.hasScope(this.scopes, neededScopes));
  }

  public static hasScope(scopes: EScope[], neededScopes: EScope[]): boolean {
    return neededScopes.every(neededScope => scopes && scopes.includes(neededScope));
  }
}
