import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationTokenStoreService } from '../services/store-services/authentication-token.store.service';

@Injectable()
export class AddHttpHeaderInterceptor implements HttpInterceptor {
  constructor(private authenticationTokenStoreService: AuthenticationTokenStoreService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return this.addHeaders$(req, next);
  }

  private addHeaders$(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const headers: { [name: string]: string } = {};

    return this.authenticationTokenStoreService.getAccessToken$().pipe(
      take(1),
      tap(accessToken => {
        if (accessToken) {
          headers['Authorization'] = `Bearer ${accessToken}`;
        }
      }),
      switchMap(() => next.handle(req.clone({ setHeaders: headers })))
    );
  }
}
