/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DownloadPathDTO } from '../model/downloadPathDTO';
import { PageSurveyRunWithUserInfoDTO } from '../model/pageSurveyRunWithUserInfoDTO';
import { RunStateChartDTO } from '../model/runStateChartDTO';
import { SurveyRunStatsDTO } from '../model/surveyRunStatsDTO';
import { SurveyRunWithUserInfoDTO } from '../model/surveyRunWithUserInfoDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class SurveyRunControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * gets a run by id
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRunById(id: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyRunWithUserInfoDTO>;
  public getRunById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyRunWithUserInfoDTO>>;
  public getRunById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyRunWithUserInfoDTO>>;
  public getRunById(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRunById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyRunWithUserInfoDTO>(`${this.basePath}/api/v1/runs/${encodeURIComponent(String(id))}/meta`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * gets all runs for company id
   * by default sorted by firstLogEntryWithTimeZone descending (newest runs first)
   * @param runId runId
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRunStateChartUsingGET(runId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<RunStateChartDTO>;
  public getRunStateChartUsingGET(runId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RunStateChartDTO>>;
  public getRunStateChartUsingGET(runId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RunStateChartDTO>>;
  public getRunStateChartUsingGET(runId: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (runId === null || runId === undefined) {
      throw new Error('Required parameter runId was null or undefined when calling getRunStateChartUsingGET.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RunStateChartDTO>(`${this.basePath}/api/v1/runs/${encodeURIComponent(String(runId))}/run-state-chart`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * gets statistics for a run by run id
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRunStatistics(id: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyRunStatsDTO>;
  public getRunStatistics(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyRunStatsDTO>>;
  public getRunStatistics(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyRunStatsDTO>>;
  public getRunStatistics(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRunStatistics.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyRunStatsDTO>(`${this.basePath}/api/v1/runs/${encodeURIComponent(String(id))}/stats`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * gets all runs for company id
   * by default sorted by firstLogEntryWithTimeZone descending (newest runs first)
   * @param companyId companyId
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRunsByCompanyIdUsingGET(companyId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageSurveyRunWithUserInfoDTO>;
  public getRunsByCompanyIdUsingGET(companyId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSurveyRunWithUserInfoDTO>>;
  public getRunsByCompanyIdUsingGET(companyId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSurveyRunWithUserInfoDTO>>;
  public getRunsByCompanyIdUsingGET(companyId: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getRunsByCompanyIdUsingGET.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageSurveyRunWithUserInfoDTO>(`${this.basePath}/api/v1/companies/${encodeURIComponent(String(companyId))}/runs`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * gets all runs for an id
   * by default sorted by firstLogEntryWithTimeZone descending (newest runs first)
   * @param surveyId surveyId
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRunsBySurveyId(surveyId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageSurveyRunWithUserInfoDTO>;
  public getRunsBySurveyId(surveyId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSurveyRunWithUserInfoDTO>>;
  public getRunsBySurveyId(surveyId: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSurveyRunWithUserInfoDTO>>;
  public getRunsBySurveyId(surveyId: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getRunsBySurveyId.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageSurveyRunWithUserInfoDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/runs`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * request download link for csv
   *
   * @param id id
   * @param timeOffsetInMinutes timeOffsetInMinutes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestDownloadRunCsv(id: string, timeOffsetInMinutes?: number, observe?: 'body', reportProgress?: boolean): Observable<DownloadPathDTO>;
  public requestDownloadRunCsv(id: string, timeOffsetInMinutes?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadPathDTO>>;
  public requestDownloadRunCsv(id: string, timeOffsetInMinutes?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadPathDTO>>;
  public requestDownloadRunCsv(id: string, timeOffsetInMinutes?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling requestDownloadRunCsv.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (timeOffsetInMinutes !== undefined && timeOffsetInMinutes !== null) {
      queryParameters = queryParameters.set('timeOffsetInMinutes', <any>timeOffsetInMinutes);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DownloadPathDTO>(`${this.basePath}/api/v1/runs/${encodeURIComponent(String(id))}/csv_report/downloadlink`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * request download link for run json
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestDownloadRunJson(id: string, observe?: 'body', reportProgress?: boolean): Observable<DownloadPathDTO>;
  public requestDownloadRunJson(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadPathDTO>>;
  public requestDownloadRunJson(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadPathDTO>>;
  public requestDownloadRunJson(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling requestDownloadRunJson.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DownloadPathDTO>(`${this.basePath}/api/v1/runs/${encodeURIComponent(String(id))}/raw_json/downloadlink`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
