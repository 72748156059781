/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { FFTResultDTO } from '../model/fFTResultDTO';
import { ParamFittingRequestDTO } from '../model/paramFittingRequestDTO';
import { SignalTestResultDTO } from '../model/signalTestResultDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class CalibrationControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * aborts calibration process and resets survey status
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abortCalibration(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public abortCalibration(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public abortCalibration(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public abortCalibration(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling abortCalibration.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/abort`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * complete data collection and set status of calibration
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public completeCollection(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public completeCollection(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public completeCollection(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public completeCollection(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling completeCollection.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/data-collection/complete`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * sets calibration to done status
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public confirmCalibration(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public confirmCalibration(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public confirmCalibration(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public confirmCalibration(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling confirmCalibration.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/confirm`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * sends notification to user and support
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public contactSupport(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public contactSupport(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public contactSupport(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public contactSupport(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling contactSupport.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/contact-support`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get fft algorithm result for survey
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFFTResult(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<FFTResultDTO>;
  public getFFTResult(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FFTResultDTO>>;
  public getFFTResult(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FFTResultDTO>>;
  public getFFTResult(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getFFTResult.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<FFTResultDTO>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/fft`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get signal quality test result for survey
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSignalTestResult(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<SignalTestResultDTO>;
  public getSignalTestResult(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignalTestResultDTO>>;
  public getSignalTestResult(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignalTestResultDTO>>;
  public getSignalTestResult(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getSignalTestResult.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SignalTestResultDTO>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/signal-test`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * initializes timestamp and state for signal test
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public initializeSignalTest(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public initializeSignalTest(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public initializeSignalTest(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public initializeSignalTest(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling initializeSignalTest.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/signal-test/init`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * resets survey to the state before collecting data for calibration
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retryDataCollection(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public retryDataCollection(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public retryDataCollection(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public retryDataCollection(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling retryDataCollection.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/data-collection/retry`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * runs FFT algorithm on specified survey
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public runFFT(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public runFFT(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public runFFT(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public runFFT(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling runFFT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/fft/start`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * runs MHPDT algorithm on specified survey
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public runMHPDT(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public runMHPDT(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public runMHPDT(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public runMHPDT(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling runMHPDT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/mhpdt`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * starts data collection for calibration for the specific survey
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startDataCollection(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public startDataCollection(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public startDataCollection(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public startDataCollection(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling startDataCollection.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/data-collection`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * runs parameter fitting algorithm on specified survey
   *
   * @param surveyId surveyId
   * @param paramFittingRequest paramFittingRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startParamFitting(surveyId: string, paramFittingRequest: ParamFittingRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public startParamFitting(surveyId: string, paramFittingRequest: ParamFittingRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public startParamFitting(surveyId: string, paramFittingRequest: ParamFittingRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public startParamFitting(surveyId: string, paramFittingRequest: ParamFittingRequestDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling startParamFitting.');
    }

    if (paramFittingRequest === null || paramFittingRequest === undefined) {
      throw new Error('Required parameter paramFittingRequest was null or undefined when calling startParamFitting.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/param-fitting`, paramFittingRequest, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * runs signal test on the collected data
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startSignalTest(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public startSignalTest(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public startSignalTest(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public startSignalTest(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling startSignalTest.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/signal-test/start`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * stop data collection and set status of calibration
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public stopCollection(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public stopCollection(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public stopCollection(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public stopCollection(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling stopCollection.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/calibration/${encodeURIComponent(String(surveyId))}/data-collection/stop`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
