import { DASHBOARD_ROUTES } from '../../../features/dashboard/dashboard-routing.config';
import { ProfileDTO } from '../../swagger-generated-client-api/model/profileDTO';

export class Profile {
  protected constructor(
    readonly id: string | undefined,
    readonly email: string | undefined,
    readonly role: ProfileDTO.RoleEnum | undefined
  ) {}

  public static fromDTO(dto: ProfileDTO) {
    return new Profile(dto.id, dto.username, dto.role);
  }

  public displayRole(): string {
    switch (this.role) {
      case ProfileDTO.RoleEnum.COMPANYADMIN:
        return 'Company admin';
      case ProfileDTO.RoleEnum.SUPERADMIN:
        return 'Super admin';
      case ProfileDTO.RoleEnum.SUPERVISOR:
        return 'Supervisor';
      case ProfileDTO.RoleEnum.USER:
        return 'User';
      default:
        return '';
    }
  }

  public getDefaultLandingPage(): string[] {
    switch (this.role) {
      case ProfileDTO.RoleEnum.SUPERADMIN:
        return DASHBOARD_ROUTES.SURVEYS.ABSOLUTE;
      default:
        return DASHBOARD_ROUTES.COMPANY_DASHBOARD.ABSOLUTE;
    }
  }
}
