/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ShiftRuleDTO {
  active: boolean | undefined;
  endDate: Date | undefined;
  frequency: ShiftRuleDTO.FrequencyEnum | undefined;
  id: string | undefined;
  name: string | undefined;
  negated: boolean | undefined;
  recurrenceEndDate: string | undefined;
  shiftRulesetId: string | undefined;
  startDate: Date | undefined;
}
export namespace ShiftRuleDTO {
  export type FrequencyEnum = 'DAY' | 'WEEKDAY' | 'WEEKEND' | 'WEEK' | 'BIWEEK' | 'MONTH' | 'QUARTER' | 'HALFYEAR' | 'YEAR';
  export const FrequencyEnum = {
    DAY: 'DAY' as FrequencyEnum,
    WEEKDAY: 'WEEKDAY' as FrequencyEnum,
    WEEKEND: 'WEEKEND' as FrequencyEnum,
    WEEK: 'WEEK' as FrequencyEnum,
    BIWEEK: 'BIWEEK' as FrequencyEnum,
    MONTH: 'MONTH' as FrequencyEnum,
    QUARTER: 'QUARTER' as FrequencyEnum,
    HALFYEAR: 'HALFYEAR' as FrequencyEnum,
    YEAR: 'YEAR' as FrequencyEnum
  };
}
