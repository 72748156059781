import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationToken } from '../../models/data-models/authentication-token';
import { AuthenticationControllerService, BaseGrantTypeDTO } from '../../swagger-generated-client-api';

// Since the swagger code-gen does not create$ this dto automatically (has problem with inheritance) we create$ it here
export interface PasswordGrantTypeDTO extends BaseGrantTypeDTO {
  grant_type: 'password';
  username: string;
  password: string;
}

export interface RefreshTokenGrantTypeDTO extends BaseGrantTypeDTO {
  grant_type: 'refresh_token';
  refresh_token: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationDataService {
  constructor(private authenticationController: AuthenticationControllerService) {}

  public signIn$(username: string, password: string): Observable<AuthenticationToken> {
    const passwordGrantTypeDTO: PasswordGrantTypeDTO = {
      grant_type: 'password',
      username,
      password
    };

    return this.authenticationController
      .getTokenUsingPOST(passwordGrantTypeDTO)
      .pipe(map(a => AuthenticationToken.fromDTO(a)));
  }

  public refresh$(refreshToken: string = ''): Observable<AuthenticationToken> {
    const refreshTokenGrantTypeDTO: RefreshTokenGrantTypeDTO = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    };

    return this.authenticationController
      .getTokenUsingPOST(refreshTokenGrantTypeDTO)
      .pipe(map(a => AuthenticationToken.fromDTO(a)));
  }
}
