/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LiveErrorCodeDTO {
  alreadySubmitted: boolean | undefined;
  andonState: LiveErrorCodeDTO.AndonStateEnum | undefined;
  andonTimestamp: Date | undefined;
  errorCodeColor: string | undefined;
  errorCodeId: string | undefined;
  errorCodeName: string | undefined;
  submissionTimestamp: Date | undefined;
  surveyId: string | undefined;
  userId: string | undefined;
  userName: string | undefined;
}
export namespace LiveErrorCodeDTO {
  export type AndonStateEnum = 'UP' | 'DOWN' | 'NA';
  export const AndonStateEnum = {
    UP: 'UP' as AndonStateEnum,
    DOWN: 'DOWN' as AndonStateEnum,
    NA: 'NA' as AndonStateEnum
  };
}
