/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SurveyFeedbackDTO {
  duration: SurveyFeedbackDTO.DurationEnum | undefined;
  recurring: boolean | undefined;
  state: SurveyFeedbackDTO.StateEnum | undefined;
}
export namespace SurveyFeedbackDTO {
  export type DurationEnum = 'RECENTLY' | 'TEN_MINUTES' | 'ONE_HOUR' | 'SHIFT' | 'DAY_OR_LONGER';
  export const DurationEnum = {
    RECENTLY: 'RECENTLY' as DurationEnum,
    TENMINUTES: 'TEN_MINUTES' as DurationEnum,
    ONEHOUR: 'ONE_HOUR' as DurationEnum,
    SHIFT: 'SHIFT' as DurationEnum,
    DAYORLONGER: 'DAY_OR_LONGER' as DurationEnum
  };
  export type StateEnum = 'WRONG_MACHINE' | 'NO_SIGNAL';
  export const StateEnum = {
    WRONGMACHINE: 'WRONG_MACHINE' as StateEnum,
    NOSIGNAL: 'NO_SIGNAL' as StateEnum
  };
}
