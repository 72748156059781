/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FFTResultDTO } from './fFTResultDTO';
import { MHPDTTimestamp } from './mHPDTTimestamp';
import { SignalTestResultDTO } from './signalTestResultDTO';

export interface RemoteCalibrationInfoDTO {
  calibrationState: RemoteCalibrationInfoDTO.CalibrationStateEnum | undefined;
  dataCollectionStopTimestamp: Date | undefined;
  dataCollectionTimestamp: Date | undefined;
  fftResult: FFTResultDTO | undefined;
  mhpdtTimestamp: MHPDTTimestamp | undefined;
  signalTestResult: SignalTestResultDTO | undefined;
  signalTestTimestamp: Date | undefined;
}
export namespace RemoteCalibrationInfoDTO {
  export type CalibrationStateEnum = 'NOT_STARTED' | 'SIGNAL_TEST_STARTED' | 'SIGNAL_TEST_COMPLETED' | 'SIGNAL_TEST_FAILED' | 'COLLECTING_STARTED' | 'COLLECTING_COMPLETED' | 'COLLECTING_FAILED' | 'FFT_COMPLETED' | 'PARAM_FITTING_COMPLETED' | 'PARAM_FITTING_FAILED' | 'CALIBRATION_CONFIRMED';
  export const CalibrationStateEnum = {
    NOTSTARTED: 'NOT_STARTED' as CalibrationStateEnum,
    SIGNALTESTSTARTED: 'SIGNAL_TEST_STARTED' as CalibrationStateEnum,
    SIGNALTESTCOMPLETED: 'SIGNAL_TEST_COMPLETED' as CalibrationStateEnum,
    SIGNALTESTFAILED: 'SIGNAL_TEST_FAILED' as CalibrationStateEnum,
    COLLECTINGSTARTED: 'COLLECTING_STARTED' as CalibrationStateEnum,
    COLLECTINGCOMPLETED: 'COLLECTING_COMPLETED' as CalibrationStateEnum,
    COLLECTINGFAILED: 'COLLECTING_FAILED' as CalibrationStateEnum,
    FFTCOMPLETED: 'FFT_COMPLETED' as CalibrationStateEnum,
    PARAMFITTINGCOMPLETED: 'PARAM_FITTING_COMPLETED' as CalibrationStateEnum,
    PARAMFITTINGFAILED: 'PARAM_FITTING_FAILED' as CalibrationStateEnum,
    CALIBRATIONCONFIRMED: 'CALIBRATION_CONFIRMED' as CalibrationStateEnum
  };
}
