import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatIconRegistry, MatSnackBarModule } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { AddHttpHeaderInterceptor } from './interceptors/add-http-header.interceptor';
import { HandleHttpErrorInterceptor } from './interceptors/handle-http-error.interceptor';
import {
  TRANSLATE_MODULE_CONFIG,
  TRANSLATE_PROVIDER_CONFIG
} from './services/translate-helper-service/translate.config';
import { ApiModule, Configuration } from './swagger-generated-client-api';

export function apiConfiguration() {
  return new Configuration({ basePath: environment.basePath });
}

const MATERIAL_CONFIG = [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }];

const ICON_TO_REGISTER = [
  { name: 'assignment_multi', path: './assets/icons/assignment-multi.svg' },
  { name: 'assignment_edit', path: './assets/icons/assignment-edit.svg' },
  { name: 'hoee-signal', path: './assets/icons/hoee-signal.svg' },
  { name: 'hoee-speed', path: './assets/icons/hoee-speed.svg' },
  { name: 'hoee-time', path: './assets/icons/hoee-time.svg' },
  { name: 'hoee-updown', path: './assets/icons/hoee-updown.svg' },
  { name: 'hoee-warning', path: './assets/icons/warning.svg' }
];

// Note: Order is important, we want to add http header after the error handling
const INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HandleHttpErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddHttpHeaderInterceptor,
    multi: true
  }
];

const MATERIAL_MODULES = [MatSnackBarModule];

@NgModule({
  imports: [
    CommonModule,
    ApiModule.forRoot(apiConfiguration),
    ...MATERIAL_MODULES,
    TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG)
  ],
  providers: [...INTERCEPTORS, ...MATERIAL_CONFIG, ...TRANSLATE_PROVIDER_CONFIG],
  declarations: []
})
export class CoreModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.registerIcons();
  }

  private registerIcons() {
    for (const icon of ICON_TO_REGISTER) {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    }
  }
}
