/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DownloadPathDTO } from '../model/downloadPathDTO';
import { PageSurveyWithOverviewDTO } from '../model/pageSurveyWithOverviewDTO';
import { SurveyWithOverviewDTO } from '../model/surveyWithOverviewDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class SurveyOverviewControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * filter all surveys for this user by optional query then get live overview data for the them
   *
   * @param q q
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMySurveysWithOverview(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageSurveyWithOverviewDTO>;
  public getMySurveysWithOverview(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSurveyWithOverviewDTO>>;
  public getMySurveysWithOverview(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSurveyWithOverviewDTO>>;
  public getMySurveysWithOverview(q?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (q !== undefined && q !== null) {
      queryParameters = queryParameters.set('q', <any>q);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageSurveyWithOverviewDTO>(`${this.basePath}/api/v1/surveyoverviews`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * fetches a single survey with overview data
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOverviewForSurvey(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyWithOverviewDTO>;
  public getOverviewForSurvey(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyWithOverviewDTO>>;
  public getOverviewForSurvey(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyWithOverviewDTO>>;
  public getOverviewForSurvey(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getOverviewForSurvey.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyWithOverviewDTO>(`${this.basePath}/api/v1/surveyoverviews/${encodeURIComponent(String(surveyId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * request download link for html
   *
   * @param surveyId surveyId
   * @param machineType machineType
   * @param from from
   * @param to to
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestShiftReportDownloadLink(surveyId: string, machineType: string, from: Date, to: Date, observe?: 'body', reportProgress?: boolean): Observable<DownloadPathDTO>;
  public requestShiftReportDownloadLink(surveyId: string, machineType: string, from: Date, to: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadPathDTO>>;
  public requestShiftReportDownloadLink(surveyId: string, machineType: string, from: Date, to: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadPathDTO>>;
  public requestShiftReportDownloadLink(surveyId: string, machineType: string, from: Date, to: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling requestShiftReportDownloadLink.');
    }

    if (machineType === null || machineType === undefined) {
      throw new Error('Required parameter machineType was null or undefined when calling requestShiftReportDownloadLink.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling requestShiftReportDownloadLink.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling requestShiftReportDownloadLink.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (machineType !== undefined && machineType !== null) {
      queryParameters = queryParameters.set('machineType', <any>machineType);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DownloadPathDTO>(`${this.basePath}/api/v1/surveyoverviews/${encodeURIComponent(String(surveyId))}/shift_report/downloadlink`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
