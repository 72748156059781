/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ExternalSurveyAndonInfoDTO } from '../model/externalSurveyAndonInfoDTO';
import { ExternalSurveyCycleInfoDTO } from '../model/externalSurveyCycleInfoDTO';
import { SurveyLiveInfoDTO } from '../model/surveyLiveInfoDTO';
import { SurveyMetadataDTO } from '../model/surveyMetadataDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ExternalSurveyControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * gets current andon state for live survey
   *
   * @param surveyId surveyId
   * @param X_API_KEY X-API-KEY
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyLiveAndonInfo(surveyId: string, X_API_KEY: string, observe?: 'body', reportProgress?: boolean): Observable<ExternalSurveyAndonInfoDTO>;
  public getSurveyLiveAndonInfo(surveyId: string, X_API_KEY: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExternalSurveyAndonInfoDTO>>;
  public getSurveyLiveAndonInfo(surveyId: string, X_API_KEY: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExternalSurveyAndonInfoDTO>>;
  public getSurveyLiveAndonInfo(surveyId: string, X_API_KEY: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getSurveyLiveAndonInfo.');
    }

    if (X_API_KEY === null || X_API_KEY === undefined) {
      throw new Error('Required parameter X_API_KEY was null or undefined when calling getSurveyLiveAndonInfo.');
    }

    let headers = this.defaultHeaders;
    if (X_API_KEY !== undefined && X_API_KEY !== null) {
      headers = headers.set('X-API-KEY', String(X_API_KEY));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ExternalSurveyAndonInfoDTO>(`${this.basePath}/api/external/v1/surveys/${encodeURIComponent(String(surveyId))}/live-andon-info`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * gets current state for live survey
   *
   * @param surveyId surveyId
   * @param X_API_KEY X-API-KEY
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyLiveCycleInfo(surveyId: string, X_API_KEY: string, observe?: 'body', reportProgress?: boolean): Observable<ExternalSurveyCycleInfoDTO>;
  public getSurveyLiveCycleInfo(surveyId: string, X_API_KEY: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExternalSurveyCycleInfoDTO>>;
  public getSurveyLiveCycleInfo(surveyId: string, X_API_KEY: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExternalSurveyCycleInfoDTO>>;
  public getSurveyLiveCycleInfo(surveyId: string, X_API_KEY: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getSurveyLiveCycleInfo.');
    }

    if (X_API_KEY === null || X_API_KEY === undefined) {
      throw new Error('Required parameter X_API_KEY was null or undefined when calling getSurveyLiveCycleInfo.');
    }

    let headers = this.defaultHeaders;
    if (X_API_KEY !== undefined && X_API_KEY !== null) {
      headers = headers.set('X-API-KEY', String(X_API_KEY));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ExternalSurveyCycleInfoDTO>(`${this.basePath}/api/external/v1/surveys/${encodeURIComponent(String(surveyId))}/live-cycle-info`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * gets current state for live survey
   *
   * @param surveyId surveyId
   * @param X_API_KEY X-API-KEY
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyLiveInfo(surveyId: string, X_API_KEY: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyLiveInfoDTO>;
  public getSurveyLiveInfo(surveyId: string, X_API_KEY: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyLiveInfoDTO>>;
  public getSurveyLiveInfo(surveyId: string, X_API_KEY: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyLiveInfoDTO>>;
  public getSurveyLiveInfo(surveyId: string, X_API_KEY: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getSurveyLiveInfo.');
    }

    if (X_API_KEY === null || X_API_KEY === undefined) {
      throw new Error('Required parameter X_API_KEY was null or undefined when calling getSurveyLiveInfo.');
    }

    let headers = this.defaultHeaders;
    if (X_API_KEY !== undefined && X_API_KEY !== null) {
      headers = headers.set('X-API-KEY', String(X_API_KEY));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyLiveInfoDTO>(`${this.basePath}/api/external/v1/surveys/${encodeURIComponent(String(surveyId))}/live-info`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * gets metadata for a survey
   *
   * @param surveyId surveyId
   * @param X_API_KEY X-API-KEY
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyMetadata(surveyId: string, X_API_KEY: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyMetadataDTO>;
  public getSurveyMetadata(surveyId: string, X_API_KEY: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyMetadataDTO>>;
  public getSurveyMetadata(surveyId: string, X_API_KEY: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyMetadataDTO>>;
  public getSurveyMetadata(surveyId: string, X_API_KEY: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getSurveyMetadata.');
    }

    if (X_API_KEY === null || X_API_KEY === undefined) {
      throw new Error('Required parameter X_API_KEY was null or undefined when calling getSurveyMetadata.');
    }

    let headers = this.defaultHeaders;
    if (X_API_KEY !== undefined && X_API_KEY !== null) {
      headers = headers.set('X-API-KEY', String(X_API_KEY));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyMetadataDTO>(`${this.basePath}/api/external/v1/surveys/${encodeURIComponent(String(surveyId))}/metadata`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
