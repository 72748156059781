/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AuthenticationTokenDTO } from '../model/authenticationTokenDTO';
import { InvitationsDTO } from '../model/invitationsDTO';
import { PasswordDTO } from '../model/passwordDTO';
import { ProfileDTO } from '../model/profileDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ProfileControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * changePassword
   *
   * @param passwordDTO passwordDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public changeProfilePassword(passwordDTO: PasswordDTO, observe?: 'body', reportProgress?: boolean): Observable<AuthenticationTokenDTO>;
  public changeProfilePassword(passwordDTO: PasswordDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthenticationTokenDTO>>;
  public changeProfilePassword(passwordDTO: PasswordDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthenticationTokenDTO>>;
  public changeProfilePassword(passwordDTO: PasswordDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (passwordDTO === null || passwordDTO === undefined) {
      throw new Error('Required parameter passwordDTO was null or undefined when calling changeProfilePassword.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<AuthenticationTokenDTO>(`${this.basePath}/api/v1/profile/change-password`, passwordDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * disables the user which matches the provided id
   *
   * @param userId userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public disableUserById(userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public disableUserById(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public disableUserById(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public disableUserById(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling disableUserById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/profile/${encodeURIComponent(String(userId))}/disable`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * enables the user which matches the provided id
   *
   * @param userId userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public enableUserById(userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public enableUserById(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public enableUserById(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public enableUserById(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling enableUserById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/profile/${encodeURIComponent(String(userId))}/enable`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * returns own profile
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public get(observe?: 'body', reportProgress?: boolean): Observable<ProfileDTO>;
  public get(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileDTO>>;
  public get(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileDTO>>;
  public get(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ProfileDTO>(`${this.basePath}/api/v1/profile`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Sends invitation emails to the specified emails
   * Returns emails, that failed to be sent
   * @param invitationsDTO invitationsDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public inviteUsers(invitationsDTO: InvitationsDTO, observe?: 'body', reportProgress?: boolean): Observable<InvitationsDTO>;
  public inviteUsers(invitationsDTO: InvitationsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InvitationsDTO>>;
  public inviteUsers(invitationsDTO: InvitationsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InvitationsDTO>>;
  public inviteUsers(invitationsDTO: InvitationsDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (invitationsDTO === null || invitationsDTO === undefined) {
      throw new Error('Required parameter invitationsDTO was null or undefined when calling inviteUsers.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<InvitationsDTO>(`${this.basePath}/api/v1/profile/invite`, invitationsDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
