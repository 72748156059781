/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { PageShiftRulesetDTO } from '../model/pageShiftRulesetDTO';
import { ShiftRulesetDTO } from '../model/shiftRulesetDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ShiftRulesetControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * creates a shift ruleset
   *
   * @param shiftRulesetDTO shiftRulesetDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createShiftRuleset(shiftRulesetDTO: ShiftRulesetDTO, observe?: 'body', reportProgress?: boolean): Observable<ShiftRulesetDTO>;
  public createShiftRuleset(shiftRulesetDTO: ShiftRulesetDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShiftRulesetDTO>>;
  public createShiftRuleset(shiftRulesetDTO: ShiftRulesetDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShiftRulesetDTO>>;
  public createShiftRuleset(shiftRulesetDTO: ShiftRulesetDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (shiftRulesetDTO === null || shiftRulesetDTO === undefined) {
      throw new Error('Required parameter shiftRulesetDTO was null or undefined when calling createShiftRuleset.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<ShiftRulesetDTO>(`${this.basePath}/api/v1/shift-rulesets`, shiftRulesetDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * deletes specified shift ruleset
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteShiftRuleset(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteShiftRuleset(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteShiftRuleset(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteShiftRuleset(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteShiftRuleset.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/api/v1/shift-rulesets/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get all shift-rulesets for the user&#39;s company
   *
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getShiftRulesets(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageShiftRulesetDTO>;
  public getShiftRulesets(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageShiftRulesetDTO>>;
  public getShiftRulesets(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageShiftRulesetDTO>>;
  public getShiftRulesets(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageShiftRulesetDTO>(`${this.basePath}/api/v1/shift-rulesets`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * updates specified shift ruleset
   *
   * @param shiftRulesetDTO shiftRulesetDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateShiftRuleset(shiftRulesetDTO: ShiftRulesetDTO, observe?: 'body', reportProgress?: boolean): Observable<ShiftRulesetDTO>;
  public updateShiftRuleset(shiftRulesetDTO: ShiftRulesetDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShiftRulesetDTO>>;
  public updateShiftRuleset(shiftRulesetDTO: ShiftRulesetDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShiftRulesetDTO>>;
  public updateShiftRuleset(shiftRulesetDTO: ShiftRulesetDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (shiftRulesetDTO === null || shiftRulesetDTO === undefined) {
      throw new Error('Required parameter shiftRulesetDTO was null or undefined when calling updateShiftRuleset.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<ShiftRulesetDTO>(`${this.basePath}/api/v1/shift-rulesets`, shiftRulesetDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
