/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BeaconDTO } from './beaconDTO';
import { CompanyDTO } from './companyDTO';
import { ErrorCodeDTO } from './errorCodeDTO';
import { GatewayDTO } from './gatewayDTO';
import { MachineDTO } from './machineDTO';
import { ProductDTO } from './productDTO';
import { ProfilerSettingsDTO } from './profilerSettingsDTO';
import { RemoteCalibrationInfoDTO } from './remoteCalibrationInfoDTO';
import { ShiftRuleDTO } from './shiftRuleDTO';

export interface SurveyWithOverviewDTO {
  andonState: SurveyWithOverviewDTO.AndonStateEnum | undefined;
  beacon: BeaconDTO | undefined;
  beaconOnline: boolean | undefined;
  calibrated: boolean | undefined;
  company: CompanyDTO | undefined;
  createdAt: Date | undefined;
  cycleTimeEnabled: boolean | undefined;
  cyclicAndonThreshold: number | undefined;
  description: string | undefined;
  downtimeNotificationEnabled: boolean | undefined;
  downtimeThreshold: number | undefined;
  emailNotificationEnabled: boolean | undefined;
  errorCodes: Array<ErrorCodeDTO> | undefined;
  filterAndonThreshold: number | undefined;
  filterOrder: SurveyWithOverviewDTO.FilterOrderEnum | undefined;
  gateway: GatewayDTO | undefined;
  gatewayOnline: boolean | undefined;
  id: string | undefined;
  isLivePredictionEnabled: boolean | undefined;
  lastRunAtTimezone: string | undefined;
  lastRunAtWithTimezone: Date | undefined;
  liveErrorCodeSubmissionTimeLimitSeconds: number | undefined;
  machine: MachineDTO | undefined;
  microDownDuration: number | undefined;
  microStateFilteringEnabled: boolean | undefined;
  microUpDuration: number | undefined;
  naNotificationEnabled: boolean | undefined;
  naThreshold: number | undefined;
  name: string | undefined;
  operationMode: SurveyWithOverviewDTO.OperationModeEnum | undefined;
  predictionEnabled: boolean | undefined;
  predictionSettings: { [key: string]: any } | undefined;
  product: ProductDTO | undefined;
  profilerSettings: ProfilerSettingsDTO | undefined;
  remoteCalibrationInfo: RemoteCalibrationInfoDTO | undefined;
  runCount: number | undefined;
  sendEmailTo: string | undefined;
  shiftDuration: number | undefined;
  shiftRules: Array<ShiftRuleDTO> | undefined;
  shiftStartTime: string | undefined;
  timezone: string | undefined;
  updatedAt: Date | undefined;
  useFilterAndon: boolean | undefined;
}
export namespace SurveyWithOverviewDTO {
  export type AndonStateEnum = 'UP' | 'DOWN' | 'DISABLED';
  export const AndonStateEnum = {
    UP: 'UP' as AndonStateEnum,
    DOWN: 'DOWN' as AndonStateEnum,
    DISABLED: 'DISABLED' as AndonStateEnum
  };
  export type FilterOrderEnum = 'UP' | 'DOWN';
  export const FilterOrderEnum = {
    UP: 'UP' as FilterOrderEnum,
    DOWN: 'DOWN' as FilterOrderEnum
  };
  export type OperationModeEnum = 'CYCLIC' | 'NON_CYCLIC';
  export const OperationModeEnum = {
    CYCLIC: 'CYCLIC' as OperationModeEnum,
    NONCYCLIC: 'NON_CYCLIC' as OperationModeEnum
  };
}
