import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { MatSnackBarDismiss } from '@angular/material/snack-bar/typings/snack-bar-ref';
import { Observable, Subject } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export const SNACK_BAR_CONFIG: MatSnackBarConfig = {
  duration: 3000
};

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  private snackBarQueue$: Subject<any> = new Subject();

  constructor(private snackBar: MatSnackBar) {
    this.snackBarQueue$.pipe(concatMap(context => this.openAndReturnAfterDismissed$(context))).subscribe();
  }

  private openAndReturnAfterDismissed$(context: any): Observable<MatSnackBarDismiss> {
    const mergedConfig = Object.assign({}, SNACK_BAR_CONFIG, context.config);
    return this.snackBar.open(context.message, undefined, mergedConfig).afterDismissed();
  }

  public newMessage(message: string, config?: MatSnackBarConfig): void {
    this.snackBarQueue$.next({ message, config });
  }
}
