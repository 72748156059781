import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationToken } from '../../models/data-models/authentication-token';
import { Profile } from '../../models/data-models/profile';
import { InvitationsDTO, ProfileControllerService } from '../../swagger-generated-client-api';

@Injectable({ providedIn: 'root' })
export class ProfileDataService {
  constructor(private profileController: ProfileControllerService) {}

  public getMe$(): Observable<Profile> {
    return this.profileController.get().pipe(map(p => Profile.fromDTO(p)));
  }

  public changePassword$(oldPassword: string, newPassword: string): Observable<AuthenticationToken> {
    return this.profileController
      .changeProfilePassword({ oldPassword, newPassword })
      .pipe(map(authTokenDTO => AuthenticationToken.fromDTO(authTokenDTO)));
  }

  public inviteUsers$(emails: string[]): Observable<InvitationsDTO> {
    return this.profileController.inviteUsers({ emails });
  }

  public enableUserById$(userId: string): Observable<void> {
    return this.profileController.enableUserById(userId);
  }

  public disableUserById$(userId: string): Observable<void> {
    return this.profileController.disableUserById(userId);
  }
}
