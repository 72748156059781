import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationToken, EScope } from '../../models/data-models/authentication-token';
import { AuthenticationDataService } from '../data-services/authentication.data.service';
import { ProfileDataService } from '../data-services/profile-data.service';
import { AuthenticationTokenStoreService } from '../store-services/authentication-token.store.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private tokensService: AuthenticationTokenStoreService,
    private authenticationDataService: AuthenticationDataService,
    private profileDataService: ProfileDataService,
    private translateService: TranslateService
  ) {}

  get isSignedIn$(): Observable<boolean> {
    return this.tokensService.getAccessToken$().pipe(
      map((accessToken: string | undefined) => !!accessToken && accessToken !== ''),
      catchError(() => of(false))
    );
  }

  // returns the new AuthenticationToken
  public signIn$(username: string, password: string): Observable<AuthenticationToken> {
    return this.authenticationDataService.signIn$(username, password).pipe(
      catchError(() => this.handleSignInError$()),
      tap(a => this.tokensService.setAuthenticationToken(a))
    );
  }

  // returns the new AuthenticationToken
  public refresh$(): Observable<AuthenticationToken> {
    return this.tokensService.getRefreshToken$().pipe(
      take(1),
      switchMap((refreshToken: string | undefined) => {
        return this.authenticationDataService.refresh$(refreshToken);
      }),
      tap((a: AuthenticationToken) => this.tokensService.setAuthenticationToken(a))
    );
  }

  public changePassword$(oldPassword: string, newPassword: string): Observable<AuthenticationToken> {
    return this.profileDataService
      .changePassword$(oldPassword, newPassword)
      .pipe(tap(authToken => this.tokensService.setAuthenticationToken(authToken)));
  }

  public signOut(): void {
    this.tokensService.removeAuthenticationToken();
  }

  private handleSignInError$(): Observable<never> {
    return _throw(new Error(this.translateService.instant('global.errors.usernameOrPasswordWrong')));
  }

  public hasScopes$(neededScopes: EScope[]): Observable<boolean> {
    return this.tokensService
      .getAuthenticationToken$()
      .pipe(
        map(token => neededScopes.every(neededScope => !!(token && token.scopes && token.scopes.includes(neededScope))))
      );
  }
}
