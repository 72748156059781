import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationToken, AuthenticationTokenLocalStorage } from '../../models/data-models/authentication-token';
import { LocalStorageStoreService } from './local-storage.store.service';

export const STORAGE_KEY = 'HARIS_GM_0';

@Injectable({ providedIn: 'root' })
export class AuthenticationTokenStoreService {
  private authenticationToken$: BehaviorSubject<AuthenticationToken | undefined>;

  constructor(private storageService: LocalStorageStoreService<AuthenticationTokenLocalStorage>) {
    this.authenticationToken$ = this.getInitialAuthenticationToken$();
    this.syncTokenWithLocalStorage();
  }

  public getAuthenticationToken$(): Observable<AuthenticationToken | undefined> {
    return this.authenticationToken$.asObservable();
  }

  public getAccessToken$(): Observable<string | undefined> {
    return this.authenticationToken$.asObservable().pipe(map(t => t && t.accessToken));
  }

  public getRefreshToken$(): Observable<string | undefined> {
    return this.authenticationToken$.asObservable().pipe(map(t => t && t.refreshToken));
  }

  public setAuthenticationToken(authenticationToken: AuthenticationToken): void {
    this.authenticationToken$.next(authenticationToken);
  }

  public removeAuthenticationToken(): void {
    this.authenticationToken$.next(undefined);
  }

  private getInitialAuthenticationToken$(): BehaviorSubject<AuthenticationToken | undefined> {
    const localStorageData = this.storageService.load(STORAGE_KEY);
    return new BehaviorSubject(localStorageData ? AuthenticationToken.deserialize(localStorageData) : undefined);
  }

  private syncTokenWithLocalStorage(): void {
    this.authenticationToken$.asObservable().subscribe(authenticationToken => {
      if (authenticationToken) {
        this.storageService.save(STORAGE_KEY, authenticationToken.serialize());
      } else {
        this.storageService.remove(STORAGE_KEY);
      }
    });
  }
}
