/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AndonStateChangeDTO {
  andonState: AndonStateChangeDTO.AndonStateEnum | undefined;
  timestamp: Date | undefined;
}
export namespace AndonStateChangeDTO {
  export type AndonStateEnum = 'UP' | 'DOWN' | 'NA';
  export const AndonStateEnum = {
    UP: 'UP' as AndonStateEnum,
    DOWN: 'DOWN' as AndonStateEnum,
    NA: 'NA' as AndonStateEnum
  };
}
