const BASE_PATH = ['/'];

export const APP_ROUTES = {
  ROOT: {
    ABSOLUTE: [...BASE_PATH],
    RELATIVE: []
  },
  DASHBOARD: {
    ABSOLUTE: [...BASE_PATH, 'dashboard'],
    RELATIVE: ['dashboard']
  },
  START: {
    ABSOLUTE: [...BASE_PATH, 'start'],
    RELATIVE: ['start']
  }
};
