/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProfileDTO {
  id: string | undefined;
  role: ProfileDTO.RoleEnum | undefined;
  username: string | undefined;
}
export namespace ProfileDTO {
  export type RoleEnum = 'SUPERADMIN' | 'COMPANYADMIN' | 'SUPERVISOR' | 'USER' | 'READONLY' | 'SERVICE_ACCOUNT';
  export const RoleEnum = {
    SUPERADMIN: 'SUPERADMIN' as RoleEnum,
    COMPANYADMIN: 'COMPANYADMIN' as RoleEnum,
    SUPERVISOR: 'SUPERVISOR' as RoleEnum,
    USER: 'USER' as RoleEnum,
    READONLY: 'READONLY' as RoleEnum,
    SERVICEACCOUNT: 'SERVICE_ACCOUNT' as RoleEnum
  };
}
