/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SurveyLiveInfoDTO {
  downStatus: SurveyLiveInfoDTO.DownStatusEnum | undefined;
  downStatusTimestamp: Date | undefined;
  predictionEnabled: boolean | undefined;
  streaming: boolean | undefined;
}
export namespace SurveyLiveInfoDTO {
  export type DownStatusEnum = 'UP' | 'DOWN' | 'NA' | 'FINISH';
  export const DownStatusEnum = {
    UP: 'UP' as DownStatusEnum,
    DOWN: 'DOWN' as DownStatusEnum,
    NA: 'NA' as DownStatusEnum,
    FINISH: 'FINISH' as DownStatusEnum
  };
}
