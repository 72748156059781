/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AndonHistoryDTO } from '../model/andonHistoryDTO';
import { DownloadPathDTO } from '../model/downloadPathDTO';
import { GatewayDTO } from '../model/gatewayDTO';
import { LiveAccelerationHistoryDTO } from '../model/liveAccelerationHistoryDTO';
import { LiveRunInfoDTO } from '../model/liveRunInfoDTO';
import { LiveSurveyReportDTO } from '../model/liveSurveyReportDTO';
import { ReferenceRunDTO } from '../model/referenceRunDTO';
import { RunStateChartDTO } from '../model/runStateChartDTO';
import { StateIntervalWithReasonDTO } from '../model/stateIntervalWithReasonDTO';
import { SurveyAndonInfoDTO } from '../model/surveyAndonInfoDTO';
import { SurveyRunDetailsDTO } from '../model/surveyRunDetailsDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class LiveSurveyControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * andon-acceleration
   *
   * @param surveyId surveyId
   * @param from from
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAndonAcceleration(surveyId: string, from?: Date, observe?: 'body', reportProgress?: boolean): Observable<LiveAccelerationHistoryDTO>;
  public getAndonAcceleration(surveyId: string, from?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LiveAccelerationHistoryDTO>>;
  public getAndonAcceleration(surveyId: string, from?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LiveAccelerationHistoryDTO>>;
  public getAndonAcceleration(surveyId: string, from?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getAndonAcceleration.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LiveAccelerationHistoryDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/get-andon-acceleration`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * list of gateways to choose from
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAvailableGatewaysForSurvey(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GatewayDTO>>;
  public getAvailableGatewaysForSurvey(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GatewayDTO>>>;
  public getAvailableGatewaysForSurvey(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GatewayDTO>>>;
  public getAvailableGatewaysForSurvey(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getAvailableGatewaysForSurvey.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<GatewayDTO>>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/get-available-gateways`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * acceleration
   *
   * @param surveyId surveyId
   * @param from from
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLiveAcceleration(surveyId: string, from?: Date, observe?: 'body', reportProgress?: boolean): Observable<LiveAccelerationHistoryDTO>;
  public getLiveAcceleration(surveyId: string, from?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LiveAccelerationHistoryDTO>>;
  public getLiveAcceleration(surveyId: string, from?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LiveAccelerationHistoryDTO>>;
  public getLiveAcceleration(surveyId: string, from?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getLiveAcceleration.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LiveAccelerationHistoryDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/get-acceleration`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * return calibration score, sensitivity and delay
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLiveRunDetails(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyRunDetailsDTO>;
  public getLiveRunDetails(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyRunDetailsDTO>>;
  public getLiveRunDetails(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyRunDetailsDTO>>;
  public getLiveRunDetails(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getLiveRunDetails.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyRunDetailsDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/details`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * getLiveRunInfo
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLiveRunInfo(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<LiveRunInfoDTO>;
  public getLiveRunInfo(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LiveRunInfoDTO>>;
  public getLiveRunInfo(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LiveRunInfoDTO>>;
  public getLiveRunInfo(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getLiveRunInfo.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LiveRunInfoDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/get-live-run-info`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * getLiveRunStateChart
   *
   * @param surveyId surveyId
   * @param from from
   * @param to to
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLiveRunStateChart(surveyId: string, from: Date, to: Date, observe?: 'body', reportProgress?: boolean): Observable<RunStateChartDTO>;
  public getLiveRunStateChart(surveyId: string, from: Date, to: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RunStateChartDTO>>;
  public getLiveRunStateChart(surveyId: string, from: Date, to: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RunStateChartDTO>>;
  public getLiveRunStateChart(surveyId: string, from: Date, to: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getLiveRunStateChart.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling getLiveRunStateChart.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling getLiveRunStateChart.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RunStateChartDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/get-live-run-state-chart`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * list of runs to choose reference cycles from
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getReferenceRuns(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ReferenceRunDTO>>;
  public getReferenceRuns(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReferenceRunDTO>>>;
  public getReferenceRuns(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReferenceRunDTO>>>;
  public getReferenceRuns(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getReferenceRuns.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<ReferenceRunDTO>>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/get-reference-cycles`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * provides andon state changes and cycles for the specified interval
   *
   * @param surveyId surveyId
   * @param from from
   * @param to to
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyAndonHistory(surveyId: string, from: Date, to: Date, observe?: 'body', reportProgress?: boolean): Observable<AndonHistoryDTO>;
  public getSurveyAndonHistory(surveyId: string, from: Date, to: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AndonHistoryDTO>>;
  public getSurveyAndonHistory(surveyId: string, from: Date, to: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AndonHistoryDTO>>;
  public getSurveyAndonHistory(surveyId: string, from: Date, to: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getSurveyAndonHistory.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling getSurveyAndonHistory.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling getSurveyAndonHistory.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<AndonHistoryDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/andon/history`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get current andon states of the provided survey
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyAndonInfo(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyAndonInfoDTO>;
  public getSurveyAndonInfo(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyAndonInfoDTO>>;
  public getSurveyAndonInfo(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyAndonInfoDTO>>;
  public getSurveyAndonInfo(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getSurveyAndonInfo.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyAndonInfoDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/andon-info`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * provides state change and error code data for specified interval
   *
   * @param surveyId surveyId
   * @param from from
   * @param to to
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyErrorCodeStateReport(surveyId: string, from: Date, to: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<StateIntervalWithReasonDTO>>;
  public getSurveyErrorCodeStateReport(surveyId: string, from: Date, to: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StateIntervalWithReasonDTO>>>;
  public getSurveyErrorCodeStateReport(surveyId: string, from: Date, to: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StateIntervalWithReasonDTO>>>;
  public getSurveyErrorCodeStateReport(surveyId: string, from: Date, to: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getSurveyErrorCodeStateReport.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling getSurveyErrorCodeStateReport.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling getSurveyErrorCodeStateReport.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<StateIntervalWithReasonDTO>>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/error-code-state-report`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get live report for survey
   *
   * @param surveyIds surveyIds
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyLiveReports(surveyIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<LiveSurveyReportDTO>;
  public getSurveyLiveReports(surveyIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LiveSurveyReportDTO>>;
  public getSurveyLiveReports(surveyIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LiveSurveyReportDTO>>;
  public getSurveyLiveReports(surveyIds: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyIds === null || surveyIds === undefined) {
      throw new Error('Required parameter surveyIds was null or undefined when calling getSurveyLiveReports.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (surveyIds) {
      surveyIds.forEach(element => {
        queryParameters = queryParameters.append('surveyIds', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LiveSurveyReportDTO>(`${this.basePath}/api/v1/surveys/live-reports`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * pauseLiveRun
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pauseLiveRun(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public pauseLiveRun(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public pauseLiveRun(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public pauseLiveRun(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling pauseLiveRun.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.put<any>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/pause-live-run`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * request download link for csv. Send UTC dates.
   *
   * @param surveyId surveyId
   * @param from from
   * @param to to
   * @param timeOffsetInMinutes timeOffsetInMinutes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestAccelerationCsvDownloadLink(surveyId: string, from: Date, to: Date, timeOffsetInMinutes?: number, observe?: 'body', reportProgress?: boolean): Observable<DownloadPathDTO>;
  public requestAccelerationCsvDownloadLink(surveyId: string, from: Date, to: Date, timeOffsetInMinutes?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadPathDTO>>;
  public requestAccelerationCsvDownloadLink(surveyId: string, from: Date, to: Date, timeOffsetInMinutes?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadPathDTO>>;
  public requestAccelerationCsvDownloadLink(surveyId: string, from: Date, to: Date, timeOffsetInMinutes?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling requestAccelerationCsvDownloadLink.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling requestAccelerationCsvDownloadLink.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling requestAccelerationCsvDownloadLink.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }
    if (timeOffsetInMinutes !== undefined && timeOffsetInMinutes !== null) {
      queryParameters = queryParameters.set('timeOffsetInMinutes', <any>timeOffsetInMinutes);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DownloadPathDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/live/acceleration_csv_report/downloadlink`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * request download link for csv. Send UTC dates.
   *
   * @param surveyId surveyId
   * @param from from
   * @param to to
   * @param timeOffsetInMinutes timeOffsetInMinutes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestCsvDownloadLink(surveyId: string, from: Date, to: Date, timeOffsetInMinutes?: number, observe?: 'body', reportProgress?: boolean): Observable<DownloadPathDTO>;
  public requestCsvDownloadLink(surveyId: string, from: Date, to: Date, timeOffsetInMinutes?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadPathDTO>>;
  public requestCsvDownloadLink(surveyId: string, from: Date, to: Date, timeOffsetInMinutes?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadPathDTO>>;
  public requestCsvDownloadLink(surveyId: string, from: Date, to: Date, timeOffsetInMinutes?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling requestCsvDownloadLink.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling requestCsvDownloadLink.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling requestCsvDownloadLink.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }
    if (timeOffsetInMinutes !== undefined && timeOffsetInMinutes !== null) {
      queryParameters = queryParameters.set('timeOffsetInMinutes', <any>timeOffsetInMinutes);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DownloadPathDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/live/csv_report/downloadlink`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * request download link for live run settings json. Send UTC dates.
   *
   * @param surveyId surveyId
   * @param predictionStartTimeUTC predictionStartTimeUTC
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestLiveRunSettingsJsonDownloadLink(surveyId: string, predictionStartTimeUTC: Date, observe?: 'body', reportProgress?: boolean): Observable<DownloadPathDTO>;
  public requestLiveRunSettingsJsonDownloadLink(surveyId: string, predictionStartTimeUTC: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadPathDTO>>;
  public requestLiveRunSettingsJsonDownloadLink(surveyId: string, predictionStartTimeUTC: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadPathDTO>>;
  public requestLiveRunSettingsJsonDownloadLink(surveyId: string, predictionStartTimeUTC: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling requestLiveRunSettingsJsonDownloadLink.');
    }

    if (predictionStartTimeUTC === null || predictionStartTimeUTC === undefined) {
      throw new Error('Required parameter predictionStartTimeUTC was null or undefined when calling requestLiveRunSettingsJsonDownloadLink.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (predictionStartTimeUTC !== undefined && predictionStartTimeUTC !== null) {
      queryParameters = queryParameters.set('predictionStartTimeUTC', <any>predictionStartTimeUTC.toISOString());
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DownloadPathDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/live/run_settings_json/downloadlink`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * startLiveRun
   *
   * @param surveyId surveyId
   * @param gatewayId gatewayId
   * @param cycleReferenceRunId cycleReferenceRunId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startLiveRun(surveyId: string, gatewayId: string, cycleReferenceRunId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public startLiveRun(surveyId: string, gatewayId: string, cycleReferenceRunId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public startLiveRun(surveyId: string, gatewayId: string, cycleReferenceRunId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public startLiveRun(surveyId: string, gatewayId: string, cycleReferenceRunId?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling startLiveRun.');
    }

    if (gatewayId === null || gatewayId === undefined) {
      throw new Error('Required parameter gatewayId was null or undefined when calling startLiveRun.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (gatewayId !== undefined && gatewayId !== null) {
      queryParameters = queryParameters.set('gatewayId', <any>gatewayId);
    }
    if (cycleReferenceRunId !== undefined && cycleReferenceRunId !== null) {
      queryParameters = queryParameters.set('cycleReferenceRunId', <any>cycleReferenceRunId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.put<any>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/start-live-run`, null, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
