/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BeaconUtilityDTO } from '../model/beaconUtilityDTO';
import { GatewayAvailabilityDTO } from '../model/gatewayAvailabilityDTO';
import { GatewayDTO } from '../model/gatewayDTO';
import { OverviewGatewayDTO } from '../model/overviewGatewayDTO';
import { PageGatewayDTO } from '../model/pageGatewayDTO';
import { ResponseEntity } from '../model/responseEntity';
import { SensorDataBeaconDTO } from '../model/sensorDataBeaconDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class GatewayControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * delete gateway
   *
   * @param gatewayId gatewayId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteGateway(gatewayId: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public deleteGateway(gatewayId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public deleteGateway(gatewayId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public deleteGateway(gatewayId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (gatewayId === null || gatewayId === undefined) {
      throw new Error('Required parameter gatewayId was null or undefined when calling deleteGateway.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<ResponseEntity>(`${this.basePath}/api/v1/gateways/${encodeURIComponent(String(gatewayId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * edit gateway
   *
   * @param gatewayId gatewayId
   * @param gatewayDTO gatewayDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editGateway(gatewayId: string, gatewayDTO: GatewayDTO, observe?: 'body', reportProgress?: boolean): Observable<GatewayDTO>;
  public editGateway(gatewayId: string, gatewayDTO: GatewayDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GatewayDTO>>;
  public editGateway(gatewayId: string, gatewayDTO: GatewayDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GatewayDTO>>;
  public editGateway(gatewayId: string, gatewayDTO: GatewayDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (gatewayId === null || gatewayId === undefined) {
      throw new Error('Required parameter gatewayId was null or undefined when calling editGateway.');
    }

    if (gatewayDTO === null || gatewayDTO === undefined) {
      throw new Error('Required parameter gatewayDTO was null or undefined when calling editGateway.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<GatewayDTO>(`${this.basePath}/api/v1/gateways/${encodeURIComponent(String(gatewayId))}/edit`, gatewayDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get all active beacons gateway
   *
   * @param gatewayId gatewayId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActiveBeacons(gatewayId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SensorDataBeaconDTO>>;
  public getActiveBeacons(gatewayId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SensorDataBeaconDTO>>>;
  public getActiveBeacons(gatewayId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SensorDataBeaconDTO>>>;
  public getActiveBeacons(gatewayId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (gatewayId === null || gatewayId === undefined) {
      throw new Error('Required parameter gatewayId was null or undefined when calling getActiveBeacons.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<SensorDataBeaconDTO>>(`${this.basePath}/api/v1/gateways/${encodeURIComponent(String(gatewayId))}/beacons/active`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get all available gateways
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAvailableGateways(observe?: 'body', reportProgress?: boolean): Observable<Array<GatewayDTO>>;
  public getAvailableGateways(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GatewayDTO>>>;
  public getAvailableGateways(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GatewayDTO>>>;
  public getAvailableGateways(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<GatewayDTO>>(`${this.basePath}/api/v1/gateways/available`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * fetches latest beacon utility from influxdb
   *
   * @param beaconDeviceId beaconDeviceId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBeaconUtility(beaconDeviceId: string, observe?: 'body', reportProgress?: boolean): Observable<BeaconUtilityDTO>;
  public getBeaconUtility(beaconDeviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BeaconUtilityDTO>>;
  public getBeaconUtility(beaconDeviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BeaconUtilityDTO>>;
  public getBeaconUtility(beaconDeviceId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (beaconDeviceId === null || beaconDeviceId === undefined) {
      throw new Error('Required parameter beaconDeviceId was null or undefined when calling getBeaconUtility.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<BeaconUtilityDTO>(`${this.basePath}/api/v1/gateways/beacon-utility/${encodeURIComponent(String(beaconDeviceId))}/latest`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * check if gateway is available
   *
   * @param gatewayId gatewayId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGatewayAvailability(gatewayId: string, observe?: 'body', reportProgress?: boolean): Observable<GatewayAvailabilityDTO>;
  public getGatewayAvailability(gatewayId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GatewayAvailabilityDTO>>;
  public getGatewayAvailability(gatewayId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GatewayAvailabilityDTO>>;
  public getGatewayAvailability(gatewayId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (gatewayId === null || gatewayId === undefined) {
      throw new Error('Required parameter gatewayId was null or undefined when calling getGatewayAvailability.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GatewayAvailabilityDTO>(`${this.basePath}/api/v1/gateways/${encodeURIComponent(String(gatewayId))}/available`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get gateway details
   *
   * @param gatewayId gatewayId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGatewayDetails(gatewayId: string, observe?: 'body', reportProgress?: boolean): Observable<GatewayDTO>;
  public getGatewayDetails(gatewayId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GatewayDTO>>;
  public getGatewayDetails(gatewayId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GatewayDTO>>;
  public getGatewayDetails(gatewayId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (gatewayId === null || gatewayId === undefined) {
      throw new Error('Required parameter gatewayId was null or undefined when calling getGatewayDetails.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GatewayDTO>(`${this.basePath}/api/v1/gateways/${encodeURIComponent(String(gatewayId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get overview if all gateways and streams
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGatewayOverview(observe?: 'body', reportProgress?: boolean): Observable<Array<OverviewGatewayDTO>>;
  public getGatewayOverview(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OverviewGatewayDTO>>>;
  public getGatewayOverview(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OverviewGatewayDTO>>>;
  public getGatewayOverview(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<OverviewGatewayDTO>>(`${this.basePath}/api/v1/gateways/overview`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get all gateways for the user&#39;s company
   *
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGateways(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageGatewayDTO>;
  public getGateways(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageGatewayDTO>>;
  public getGateways(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageGatewayDTO>>;
  public getGateways(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageGatewayDTO>(`${this.basePath}/api/v1/gateways`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * creates new gateway for the user&#39;s company
   *
   * @param gatewayDTO gatewayDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public registerGateway(gatewayDTO: GatewayDTO, observe?: 'body', reportProgress?: boolean): Observable<GatewayDTO>;
  public registerGateway(gatewayDTO: GatewayDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GatewayDTO>>;
  public registerGateway(gatewayDTO: GatewayDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GatewayDTO>>;
  public registerGateway(gatewayDTO: GatewayDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (gatewayDTO === null || gatewayDTO === undefined) {
      throw new Error('Required parameter gatewayDTO was null or undefined when calling registerGateway.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<GatewayDTO>(`${this.basePath}/api/v1/gateways`, gatewayDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
