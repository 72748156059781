/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ProductDTO } from '../model/productDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ProductControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * filter all products for this survey&#39;s company
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAvailableProductsForSurvey(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProductDTO>>;
  public getAvailableProductsForSurvey(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductDTO>>>;
  public getAvailableProductsForSurvey(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductDTO>>>;
  public getAvailableProductsForSurvey(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getAvailableProductsForSurvey.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<ProductDTO>>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/products`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
