import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, first, switchMap } from 'rxjs/operators';
import { APP_ROUTES } from '../../app-routing.config';
import { AuthenticationService } from '../services/core-services/authentication.service';
import { SnackBarService } from '../services/core-services/snack-bar.service';
import { FieldErrorDTO, ObjectErrorDTO, RestErrorDTO } from '../swagger-generated-client-api';

@Injectable()
export class HandleHttpErrorInterceptor implements HttpInterceptor {
  private alreadyHandling401Error$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackbarService: SnackBarService,
    private translateService: TranslateService
  ) {}

  // ts-ignore
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return next.handle(req).pipe(
      catchError(errorResponse => {
        switch (errorResponse.status) {
          case 401:
            return this.handle401Error$(req, next, errorResponse);
          default:
            return this.handleAllOtherErrors$(errorResponse);
        }
      })
    );
  }

  /*
  Automatically does OAuth-Refresh and re-send requested request
   */
  private handle401Error$(
    req: HttpRequest<any>,
    next: HttpHandler,
    errorResponse: HttpErrorResponse
  ): Observable<HttpEvent<any>> | Observable<any> {
    // Don't refresh the refresh-token call itself
    if (req.url.includes('token')) {
      return throwError(new Error(errorResponse.message));
    }

    // If alreadyHandling401Error, wait for it to be false again
    if (this.alreadyHandling401Error$.getValue()) {
      return this.alreadyHandling401Error$.pipe(
        filter(alreadyHandling401Error => !alreadyHandling401Error),
        first(),
        switchMap(() => next.handle(req.clone()))
      );
    }

    this.alreadyHandling401Error$.next(true);
    return this.authenticationService.refresh$().pipe(
      catchError(() => {
        // Refresh didn't work - so log out
        this.authenticationService.signOut();
        this.router.navigate(APP_ROUTES.START.ABSOLUTE);
        return throwError(new Error(errorResponse.message));
      }),
      switchMap(() => {
        // Refresh worked - resend request
        return next.handle(req.clone());
      }),
      finalize(() => this.alreadyHandling401Error$.next(false))
    );
  }

  private handleAllOtherErrors$(errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> | Observable<any> {
    const restError: RestErrorDTO | undefined = errorResponse && errorResponse.error;
    let mainMessage = `${restError && restError.message}`;

    if (restError && restError.globalErrors && restError.globalErrors.length > 0) {
      mainMessage += ', ' + restError.globalErrors.map((globalError: ObjectErrorDTO) => globalError.message).join('\n');
    }

    if (restError && restError.fieldErrors && restError.fieldErrors.length > 0) {
      mainMessage += ', ' + restError.fieldErrors.map((fieldError: FieldErrorDTO) => fieldError.message).join('\n');
    }
    this.snackbarService.newMessage(
      mainMessage ? mainMessage : this.translateService.instant('global.errors.unhandledErrorOccured')
    );

    return throwError(new Error(errorResponse.message));
  }
}
