import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor(private translateService: TranslateService) {}

  public loadScript$(src: string, id?: string): Observable<HTMLScriptElement> {
    return Observable.create((subscriber: Subscriber<HTMLScriptElement>) => {
      const script: HTMLScriptElement = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.id = id || '';
      script.onload = () => {
        subscriber.next(script);
        subscriber.complete();
      };
      script.onerror = () =>
        subscriber.error(`${this.translateService.instant('global.errors.couldNotLoadScript')}: ${src}`);
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
}
