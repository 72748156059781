import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsSignedInGuard } from './core/guards/is-signed-in-guard.service';

// https://github.com/angular/angular-cli/issues/4192
export const PATH_DASHBOARD = 'dashboard';
export const PATH_START = 'start';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: PATH_DASHBOARD,
    pathMatch: 'full'
  },
  {
    path: PATH_DASHBOARD,
    // canLoad: [IsSignedInGuard],
    // canActivate: [IsSignedInGuard],
    loadChildren: './features/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: PATH_START,
    loadChildren: './features/start/start.module#StartModule'
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
