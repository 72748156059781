import { APP_ROUTES } from '../../app-routing.config';

const BASE_PATH = APP_ROUTES.DASHBOARD.ABSOLUTE;

export const DASHBOARD_ROUTES = {
  ROOT: {
    ABSOLUTE: [...BASE_PATH],
    RELATIVE: []
  },
  SURVEYS: {
    ABSOLUTE: [...BASE_PATH, 'surveys'],
    RELATIVE: ['surveys']
  },
  COMPANY_DASHBOARD: {
    ABSOLUTE: [...BASE_PATH, 'company-dashboard'],
    RELATIVE: ['company-dashboard']
  },
  TENANTS: {
    ABSOLUTE: [...BASE_PATH, 'tenants'],
    RELATIVE: ['tenants']
  },
  LIVE_STREAMS: {
    ABSOLUTE: [...BASE_PATH, 'live-streams-overview'],
    RELATIVE: ['live-streams-overview']
  },
  GATEWAYS: {
    ABSOLUTE: [...BASE_PATH, 'gateways'],
    RELATIVE: ['gateways']
  },
  INVITE_USERS: {
    ABSOLUTE: [...BASE_PATH, 'invite-users'],
    RELATIVE: ['invite-users']
  },
  API_KEYS: {
    ABSOLUTE: [...BASE_PATH, 'api-keys'],
    RELATIVE: ['api-keys']
  },
  MACHINES: {
    ABSOLUTE: [...BASE_PATH, 'machines'],
    RELATIVE: ['machines']
  }
};
