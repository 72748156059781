/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { PageSurveyDTO } from '../model/pageSurveyDTO';
import { PageSurveyWithAndonInfoDTO } from '../model/pageSurveyWithAndonInfoDTO';
import { ProfileDTO } from '../model/profileDTO';
import { SurveyDTO } from '../model/surveyDTO';
import { SurveyFeedbackDTO } from '../model/surveyFeedbackDTO';
import { SurveyWithAndonInfoDTO } from '../model/surveyWithAndonInfoDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class SurveyControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * clone a survey
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cloneSurvey(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public cloneSurvey(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public cloneSurvey(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public cloneSurvey(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling cloneSurvey.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(id))}/clone`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * creates a survey
   *
   * @param surveyDTO surveyDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createSurvey(surveyDTO: SurveyDTO, observe?: 'body', reportProgress?: boolean): Observable<SurveyDTO>;
  public createSurvey(surveyDTO: SurveyDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyDTO>>;
  public createSurvey(surveyDTO: SurveyDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyDTO>>;
  public createSurvey(surveyDTO: SurveyDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyDTO === null || surveyDTO === undefined) {
      throw new Error('Required parameter surveyDTO was null or undefined when calling createSurvey.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<SurveyDTO>(`${this.basePath}/api/v1/surveys`, surveyDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * deletes specified survey
   *
   * @param id id
   * @param permanent permanent
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteSurvey(id: string, permanent?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteSurvey(id: string, permanent?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteSurvey(id: string, permanent?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteSurvey(id: string, permanent?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteSurvey.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (permanent !== undefined && permanent !== null) {
      queryParameters = queryParameters.set('permanent', <any>permanent);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(id))}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * fetches the company admin user of the given survey
   *
   * @param surveyId surveyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyAdmin(surveyId: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileDTO>;
  public getCompanyAdmin(surveyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileDTO>>;
  public getCompanyAdmin(surveyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileDTO>>;
  public getCompanyAdmin(surveyId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling getCompanyAdmin.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ProfileDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(surveyId))}/company-admin`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * filter all surveys for this user by optional query
   *
   * @param q q
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMySurveys(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageSurveyDTO>;
  public getMySurveys(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSurveyDTO>>;
  public getMySurveys(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSurveyDTO>>;
  public getMySurveys(q?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (q !== undefined && q !== null) {
      queryParameters = queryParameters.set('q', <any>q);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageSurveyDTO>(`${this.basePath}/api/v1/surveys`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * filter all surveys including Andon info for this user by optional query
   *
   * @param q q
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMySurveysWithAndonInfo(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageSurveyWithAndonInfoDTO>;
  public getMySurveysWithAndonInfo(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSurveyWithAndonInfoDTO>>;
  public getMySurveysWithAndonInfo(q?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSurveyWithAndonInfoDTO>>;
  public getMySurveysWithAndonInfo(q?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (q !== undefined && q !== null) {
      queryParameters = queryParameters.set('q', <any>q);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageSurveyWithAndonInfoDTO>(`${this.basePath}/api/v1/surveys/andon-info`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * gets survey for an id
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyById(id: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyDTO>;
  public getSurveyById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyDTO>>;
  public getSurveyById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyDTO>>;
  public getSurveyById(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getSurveyById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * get a single survey with error code info
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSurveyErrorCodeInfo(id: string, observe?: 'body', reportProgress?: boolean): Observable<SurveyWithAndonInfoDTO>;
  public getSurveyErrorCodeInfo(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyWithAndonInfoDTO>>;
  public getSurveyErrorCodeInfo(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyWithAndonInfoDTO>>;
  public getSurveyErrorCodeInfo(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getSurveyErrorCodeInfo.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SurveyWithAndonInfoDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(id))}/error-code-info`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * requeue runs of a survey
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requeueSurveyRuns(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public requeueSurveyRuns(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public requeueSurveyRuns(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public requeueSurveyRuns(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling requeueSurveyRuns.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(id))}/trigger`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * sends notification to user and support
   *
   * @param id id
   * @param surveyFeedbackDTO surveyFeedbackDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendSurveyFeedback(id: string, surveyFeedbackDTO: SurveyFeedbackDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public sendSurveyFeedback(id: string, surveyFeedbackDTO: SurveyFeedbackDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public sendSurveyFeedback(id: string, surveyFeedbackDTO: SurveyFeedbackDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public sendSurveyFeedback(id: string, surveyFeedbackDTO: SurveyFeedbackDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling sendSurveyFeedback.');
    }

    if (surveyFeedbackDTO === null || surveyFeedbackDTO === undefined) {
      throw new Error('Required parameter surveyFeedbackDTO was null or undefined when calling sendSurveyFeedback.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(id))}/feedback`, surveyFeedbackDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * updates specified survey
   * updates name, machine, product, shift start, shift duration
   * @param id id
   * @param surveyDTO surveyDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSurvey(id: string, surveyDTO: SurveyDTO, observe?: 'body', reportProgress?: boolean): Observable<SurveyDTO>;
  public updateSurvey(id: string, surveyDTO: SurveyDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyDTO>>;
  public updateSurvey(id: string, surveyDTO: SurveyDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyDTO>>;
  public updateSurvey(id: string, surveyDTO: SurveyDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateSurvey.');
    }

    if (surveyDTO === null || surveyDTO === undefined) {
      throw new Error('Required parameter surveyDTO was null or undefined when calling updateSurvey.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<SurveyDTO>(`${this.basePath}/api/v1/surveys/${encodeURIComponent(String(id))}`, surveyDTO, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
