import { Injectable } from '@angular/core';

export const SECRET_KEY = '9dvDRRuk5sat32rv';

@Injectable({ providedIn: 'root' })
export class LocalStorageStoreService<Data> {
  public load(key: string): Data | undefined {
    try {
      return this.decode(localStorage.getItem(key) || undefined);
    } catch {
      // if localStorage can't be accessed just do nothing
      return undefined;
    }
  }

  public save(key: string, data: Data) {
    try {
      localStorage.setItem(key, this.encode(data));
    } catch {
      // if localStorage can't be accessed just do nothing
    }
  }

  public remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch {
      // if localStorage can't be accessed just do nothing
    }
  }

  private encode(data: Data): string {
    return JSON.stringify(data);
  }

  private decode(localStorageContent: string | undefined): Data | undefined {
    if (localStorageContent) {
      return JSON.parse(localStorageContent) as Data;
    } else {
      return undefined;
    }
  }
}
