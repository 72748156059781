import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { EScope } from './core/models/data-models/authentication-token';
import { AuthenticationService } from './core/services/core-services/authentication.service';
import { ScriptService } from './core/services/core-services/script.service';

@Component({
  selector: 'hoee-gm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'haris-oee-gm-frontend';

  constructor(private authenticationService: AuthenticationService, private scriptService: ScriptService) {}

  public ngOnInit(): void {
    /* tslint:disable-next-line:no-console */
    console.log(this.title + ' v' + environment.version);

    this.authenticationService
      .hasScopes$([EScope.RUNS_VIEW_COMPANY])
      .pipe(
        untilDestroyed(this),
        filter(hasScope => hasScope),
        switchMap(() =>
          this.scriptService.loadScript$(
            'https://static.zdassets.com/ekr/snippet.js?key=4b7facbf-f3b4-496b-8716-cdad936b8442',
            'ze-snippet'
          )
        )
      )
      .subscribe();
  }

  public ngOnDestroy(): void {}
}
