export * from './apiKeyController.service';
import { ApiKeyControllerService } from './apiKeyController.service';
export * from './authTestController.service';
import { AuthTestControllerService } from './authTestController.service';
export * from './authenticationController.service';
import { AuthenticationControllerService } from './authenticationController.service';
export * from './calibrationController.service';
import { CalibrationControllerService } from './calibrationController.service';
export * from './dailyReportGeneratorController.service';
import { DailyReportGeneratorControllerService } from './dailyReportGeneratorController.service';
export * from './errorCodeController.service';
import { ErrorCodeControllerService } from './errorCodeController.service';
export * from './externalSurveyController.service';
import { ExternalSurveyControllerService } from './externalSurveyController.service';
export * from './gatewayController.service';
import { GatewayControllerService } from './gatewayController.service';
export * from './liveSurveyController.service';
import { LiveSurveyControllerService } from './liveSurveyController.service';
export * from './machineController.service';
import { MachineControllerService } from './machineController.service';
export * from './productController.service';
import { ProductControllerService } from './productController.service';
export * from './profileController.service';
import { ProfileControllerService } from './profileController.service';
export * from './publicDownloadController.service';
import { PublicDownloadControllerService } from './publicDownloadController.service';
export * from './publicPasswordResetController.service';
import { PublicPasswordResetControllerService } from './publicPasswordResetController.service';
export * from './publicRegistrationController.service';
import { PublicRegistrationControllerService } from './publicRegistrationController.service';
export * from './publicVersionController.service';
import { PublicVersionControllerService } from './publicVersionController.service';
export * from './shiftGeneratorController.service';
import { ShiftGeneratorControllerService } from './shiftGeneratorController.service';
export * from './shiftRuleController.service';
import { ShiftRuleControllerService } from './shiftRuleController.service';
export * from './shiftRulesetController.service';
import { ShiftRulesetControllerService } from './shiftRulesetController.service';
export * from './surveyController.service';
import { SurveyControllerService } from './surveyController.service';
export * from './surveyOverviewController.service';
import { SurveyOverviewControllerService } from './surveyOverviewController.service';
export * from './surveyRunController.service';
import { SurveyRunControllerService } from './surveyRunController.service';
export * from './surveySynchronizeController.service';
import { SurveySynchronizeControllerService } from './surveySynchronizeController.service';
export * from './tenantController.service';
import { TenantControllerService } from './tenantController.service';
export const APIS = [ApiKeyControllerService, AuthTestControllerService, AuthenticationControllerService, CalibrationControllerService, DailyReportGeneratorControllerService, ErrorCodeControllerService, ExternalSurveyControllerService, GatewayControllerService, LiveSurveyControllerService, MachineControllerService, ProductControllerService, ProfileControllerService, PublicDownloadControllerService, PublicPasswordResetControllerService, PublicRegistrationControllerService, PublicVersionControllerService, ShiftGeneratorControllerService, ShiftRuleControllerService, ShiftRulesetControllerService, SurveyControllerService, SurveyOverviewControllerService, SurveyRunControllerService, SurveySynchronizeControllerService, TenantControllerService];
