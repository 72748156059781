/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Resource } from '../model/resource';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class PublicDownloadControllerService {
  protected basePath = 'https://localhost:8090';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * downloads a live run settings raw json
   *
   * @param surveyId surveyId
   * @param predictionStartTimeUTC predictionStartTimeUTC
   * @param token token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadLiveRunSettingsJson(surveyId: string, predictionStartTimeUTC: Date, token: string, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
  public downloadLiveRunSettingsJson(surveyId: string, predictionStartTimeUTC: Date, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
  public downloadLiveRunSettingsJson(surveyId: string, predictionStartTimeUTC: Date, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
  public downloadLiveRunSettingsJson(surveyId: string, predictionStartTimeUTC: Date, token: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling downloadLiveRunSettingsJson.');
    }

    if (predictionStartTimeUTC === null || predictionStartTimeUTC === undefined) {
      throw new Error('Required parameter predictionStartTimeUTC was null or undefined when calling downloadLiveRunSettingsJson.');
    }

    if (token === null || token === undefined) {
      throw new Error('Required parameter token was null or undefined when calling downloadLiveRunSettingsJson.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (predictionStartTimeUTC !== undefined && predictionStartTimeUTC !== null) {
      queryParameters = queryParameters.set('predictionStartTimeUTC', <any>predictionStartTimeUTC.toISOString());
    }
    if (token !== undefined && token !== null) {
      queryParameters = queryParameters.set('token', <any>token);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Resource>(`${this.basePath}/api/public/v1/download/surveys/${encodeURIComponent(String(surveyId))}/live/run_settings_json/download`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * downloads a csv of run states
   *
   * @param surveyId surveyId
   * @param from from
   * @param to to
   * @param token token
   * @param timeOffsetInMinutes timeOffsetInMinutes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadLiveSurveyAccelerationCsv(surveyId: string, from: Date, to: Date, token: string, timeOffsetInMinutes?: number, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
  public downloadLiveSurveyAccelerationCsv(surveyId: string, from: Date, to: Date, token: string, timeOffsetInMinutes?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
  public downloadLiveSurveyAccelerationCsv(surveyId: string, from: Date, to: Date, token: string, timeOffsetInMinutes?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
  public downloadLiveSurveyAccelerationCsv(surveyId: string, from: Date, to: Date, token: string, timeOffsetInMinutes?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling downloadLiveSurveyAccelerationCsv.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling downloadLiveSurveyAccelerationCsv.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling downloadLiveSurveyAccelerationCsv.');
    }

    if (token === null || token === undefined) {
      throw new Error('Required parameter token was null or undefined when calling downloadLiveSurveyAccelerationCsv.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }
    if (timeOffsetInMinutes !== undefined && timeOffsetInMinutes !== null) {
      queryParameters = queryParameters.set('timeOffsetInMinutes', <any>timeOffsetInMinutes);
    }
    if (token !== undefined && token !== null) {
      queryParameters = queryParameters.set('token', <any>token);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Resource>(`${this.basePath}/api/public/v1/download/surveys/${encodeURIComponent(String(surveyId))}/live/acceleration_csv_report/download`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * downloads a csv of run states
   *
   * @param surveyId surveyId
   * @param from from
   * @param to to
   * @param token token
   * @param timeOffsetInMinutes timeOffsetInMinutes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadLiveSurveyStatesCsv(surveyId: string, from: Date, to: Date, token: string, timeOffsetInMinutes?: number, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
  public downloadLiveSurveyStatesCsv(surveyId: string, from: Date, to: Date, token: string, timeOffsetInMinutes?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
  public downloadLiveSurveyStatesCsv(surveyId: string, from: Date, to: Date, token: string, timeOffsetInMinutes?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
  public downloadLiveSurveyStatesCsv(surveyId: string, from: Date, to: Date, token: string, timeOffsetInMinutes?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling downloadLiveSurveyStatesCsv.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling downloadLiveSurveyStatesCsv.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling downloadLiveSurveyStatesCsv.');
    }

    if (token === null || token === undefined) {
      throw new Error('Required parameter token was null or undefined when calling downloadLiveSurveyStatesCsv.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }
    if (timeOffsetInMinutes !== undefined && timeOffsetInMinutes !== null) {
      queryParameters = queryParameters.set('timeOffsetInMinutes', <any>timeOffsetInMinutes);
    }
    if (token !== undefined && token !== null) {
      queryParameters = queryParameters.set('token', <any>token);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Resource>(`${this.basePath}/api/public/v1/download/surveys/${encodeURIComponent(String(surveyId))}/live/csv_report/download`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * downloads a run raw json
   *
   * @param runId runId
   * @param token token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadRunJson(runId: string, token: string, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
  public downloadRunJson(runId: string, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
  public downloadRunJson(runId: string, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
  public downloadRunJson(runId: string, token: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (runId === null || runId === undefined) {
      throw new Error('Required parameter runId was null or undefined when calling downloadRunJson.');
    }

    if (token === null || token === undefined) {
      throw new Error('Required parameter token was null or undefined when calling downloadRunJson.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (token !== undefined && token !== null) {
      queryParameters = queryParameters.set('token', <any>token);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Resource>(`${this.basePath}/api/public/v1/download/runs/${encodeURIComponent(String(runId))}/raw_json/download`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * downloads a csv of run states
   *
   * @param runId runId
   * @param token token
   * @param timeOffsetInMinutes timeOffsetInMinutes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadRunStatesCsv(runId: string, token: string, timeOffsetInMinutes?: number, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
  public downloadRunStatesCsv(runId: string, token: string, timeOffsetInMinutes?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
  public downloadRunStatesCsv(runId: string, token: string, timeOffsetInMinutes?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
  public downloadRunStatesCsv(runId: string, token: string, timeOffsetInMinutes?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (runId === null || runId === undefined) {
      throw new Error('Required parameter runId was null or undefined when calling downloadRunStatesCsv.');
    }

    if (token === null || token === undefined) {
      throw new Error('Required parameter token was null or undefined when calling downloadRunStatesCsv.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (token !== undefined && token !== null) {
      queryParameters = queryParameters.set('token', <any>token);
    }
    if (timeOffsetInMinutes !== undefined && timeOffsetInMinutes !== null) {
      queryParameters = queryParameters.set('timeOffsetInMinutes', <any>timeOffsetInMinutes);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Resource>(`${this.basePath}/api/public/v1/download/runs/${encodeURIComponent(String(runId))}/csv_report/download`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * downloads shift report in html
   *
   * @param surveyId surveyId
   * @param machineType machineType
   * @param from from
   * @param to to
   * @param token token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadShiftReportHtml(surveyId: string, machineType: string, from: Date, to: Date, token: string, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
  public downloadShiftReportHtml(surveyId: string, machineType: string, from: Date, to: Date, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
  public downloadShiftReportHtml(surveyId: string, machineType: string, from: Date, to: Date, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
  public downloadShiftReportHtml(surveyId: string, machineType: string, from: Date, to: Date, token: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (surveyId === null || surveyId === undefined) {
      throw new Error('Required parameter surveyId was null or undefined when calling downloadShiftReportHtml.');
    }

    if (machineType === null || machineType === undefined) {
      throw new Error('Required parameter machineType was null or undefined when calling downloadShiftReportHtml.');
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling downloadShiftReportHtml.');
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling downloadShiftReportHtml.');
    }

    if (token === null || token === undefined) {
      throw new Error('Required parameter token was null or undefined when calling downloadShiftReportHtml.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (machineType !== undefined && machineType !== null) {
      queryParameters = queryParameters.set('machineType', <any>machineType);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }
    if (token !== undefined && token !== null) {
      queryParameters = queryParameters.set('token', <any>token);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Resource>(`${this.basePath}/api/public/v1/download/surveys/${encodeURIComponent(String(surveyId))}/shift_report_html/download`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
